<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="white-space: nowrap;font-weight: 700;text-align: right;width: 80px;">关键字：</label>
				<el-input v-model="searchParams.Keywords" placeholder="公司名称" style="width: 250px;"></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" @click="handleSearch">查询</el-button>
			</div>
		</div>
		

		<el-table :data="list" v-loading="loading" ref="multipleTable" class="clue-table" row-key="Id">
			<el-table-column prop="ClueNo" label="线索编码" width="160px" fixed="left">
				<template slot-scope="scope">
					<div class="flex flex-align-center ellipsis" style="color: #409EFF;cursor: pointer;" @click="handleToDetails(scope.row)">
						{{ scope.row.ClueNo }}
						<div class="tag" v-if="scope.row.IsRepeat">重复</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="CompanyName" label="公司名称" width="220px" fixed="left">
				<template slot-scope="scope">
					<el-popover
						placement="bottom"
						title=""
						width="200"
						trigger="hover"
						:content="scope.row.CompanyName">
						<div class="ellipsis" slot="reference" style="color: #409EFF;cursor: pointer;" @click="handleToDetails(scope.row)">
							{{ scope.row.CompanyName }}
						</div>
					  </el-popover>
				</template>
			</el-table-column>
			<el-table-column prop="CRMClueFromName" label="线索来源">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.CRMClueFromName" class="ellipsis">
							{{ scope.row.CRMClueFromName }}
						</div>
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="EmployeeName" label="负责人">
				<template slot-scope="scope">
					<div>
						<div class="gap-10" v-if="scope.row.EmployeeName || scope.row.EmployeePhone">
							<div class="ellipsis" style="white-space: nowrap;">{{ scope.row.EmployeeName || '--' }}</div>
							<div class="ellipsis" style="white-space: nowrap;">{{ scope.row.EmployeePhone || '--' }}</div>
						</div>
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="SellRemark" label="销售备注">
				<template slot-scope="scope">
					<div>
						<el-popover
							v-if="scope.row.SellRemark"
							placement="bottom"
							title=""
							width="400"
							trigger="hover"
							:content="scope.row.SellRemark">
							<div slot="reference" class="ellipsis">
								{{ scope.row.SellRemark }}
							</div>
						  </el-popover>
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="CancelTypeValue" label="作废原因">
				<template slot-scope="scope">
					<div>
						<div class="ellipsis">
							{{ scope.row.CancelTypeValue || '--' }}
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="CancelRemark" label="作废备注">
				<template slot-scope="scope">
					<div>
						<el-popover
							v-if="scope.row.CancelRemark"
							placement="bottom"
							title=""
							width="400"
							trigger="hover"
							:content="scope.row.CancelRemark">
							<div slot="reference" class="ellipsis">
								{{ scope.row.CancelRemark }}
							</div>
						  </el-popover>
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="CancelTime" label="作废时间" width="160px">
			</el-table-column>
			<el-table-column prop="" label="操作" width="80px" fixed="right">
				<template slot-scope="scope">
					<div class="flex flex-align-center flex-wrap gap-10">
						<div>
							<el-button type="text" @click="handleToDetails(scope.row)">详情</el-button>
						</div>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="page-box">
			<div class="left">
			</div>
			<el-pagination style="text-align:right" class="margin-top-10" v-if="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		
		
		
		
		
	</div>
</template>

<script>
	
	import {
		cRMClueCancelList
	} from '@/api/cluesManage';
	
	import config from '@/config/index';
	
	import buttonPermissions from '@/components/buttonPermissions';
	
	export default {
		components: {buttonPermissions},
		data() {
			return {
				//列表
				list: [],
				total: 0,
				pageIndex: 1,
				pageSize: 20,
				loading: false,

				searchParams: {
					Keywords: ''
				}
			}
		},
		methods: {
			async getList(){
				this.loading = true;
				
				try{
					let params = {
						Skip: (this.pageIndex-1) * this.pageSize,
						Take: this.pageSize,
						...this.searchParams
					};
					const res = await cRMClueCancelList(params);
					this.list = res.Result.Results;
					this.total = res.Result.Total;
					this.loading = false;
				}catch(e){
					this.loading = false;
				}
			},
			
			/**
			 * 分页
			 */
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.pageIndex = val;
				this.getList();
			},
			
			/**
			 * 搜索
			 */
			handleSearch(){
				this.pageIndex = 1;
				this.getList();
			},
			
			/**
			 * 详情
			 */
			handleToDetails(record){
				this.$router.push({
					name: 'clueDataDetails',
					query: {
						id: record.Id,
						key: 'clueData'
					}
				})
			},
			
			
		},
		created() {
			this.getList();
		}
	}
</script>

<style lang="less" scoped>
	
.page-box{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 15px;
}
.clue-table ::v-deep th,
.clue-table ::v-deep tr{
	background: #fff;
}

</style>