import request from '@/utils/request'

//线索来源页
export const cRMClueFromList = (data)=> request('/pc/cRMClueFrom/list',data);
export const cRMClueFromSave = (data)=> request('/pc/cRMClueFrom/save',data);
export const cRMClueFromDelete = (data)=> request('/pc/cRMClueFrom/delete',data);

//线索池页
export const cRMClueList = (data)=> request('/pc/cRMClue/list',data);
export const cRMClueFromFilterBoxList = (data)=> request('/pc/cRMClueFrom/filterBoxList',data);
export const cRMClueSave = (data)=> request('/pc/cRMClue/save',data);
export const cRMClueCancel = (data)=> request('/pc/cRMClue/cancel',data);
export const cRMClueAllocate = (data)=> request('/pc/cRMClue/allocate',data);
export const cRMClueChangeMallEmployeeId = (data)=> request('/pc/cRMClue/changeMallEmployeeId',data);
export const cRMClueAllocateJudgeState = (data)=> request('/pc/cRMClue/allocateJudgeState',data);
export const cRMClueInfo = (data)=> request('/pc/cRMClue/info',data);
export const cRMClueFollowUp = (data)=> request('/pc/cRMClue/followUp',data);
export const cRMClueReturnPool = (data)=> request('/pc/cRMClue/returnPool',data);
export const cRMClueRecordList = (data)=> request('/pc/cRMClueRecord/list',data);
export const cRMClueIsExistsProgress = (data)=> request('/pc/cRMClue/isExistsProgress',data);
export const cRMClueImportiscomplete = (data)=> request('/pc/cRMClue/importiscomplete',data);
export const cRMClueImport = (data)=> request('/pc/cRMClue/import',data);

//销售线索
export const cRMClueSellList = (data)=> request('/pc/cRMClue/sellList',data);

//线索回收站
export const cRMClueCancelList = (data)=> request('/pc/cRMClue/cancelList',data);


































































